import { Search } from "@mui/icons-material";
import { Avatar, AvatarGroup, Box, IconButton, InputBase, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import ServerProxy from "./serverProxy";
import config from "../../config";


const Root = styled(Box)(({ theme }) => ({

    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '64px',
}));


const ColorBox = styled(Box)(({ theme }) => ({
    color: '#fff',
    textAlign: 'center',
    borderRadius: 10,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: 'rgba( 255, 255, 255, 0.2)',
    minHeight: 120,

    width: '25%',
    margin: '.5rem',
    flexGrow: 1,
    padding: '.5rem',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: theme.palette.primary.light
    }
}));



const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 3,
    cursor: 'pointer',


}));


function MyDrawer(props) {

    const { children, open } = props;

    const theme = useTheme();

    return (
        <Box
            sx={{
                width: '100%',
                height: "100%",
                opacity: 1,
                overflow: "hidden",
                transition: theme.transitions.create(['height', 'opacity'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                })
            }}

            className={!open ? 'altezza-ridotta' : ''}
        >{children}</Box>
    )

}



export default function MainToolBar(props) {

    const theme = useTheme();
    const inputRef = useRef(null);

    const isLargeScreenPredefined = useMediaQuery(theme.breakpoints.up('lg'));
    const { setOpenDialogBestMatch, onOpenFilter, helpPanel, open, defaultData } = props;


    function onSearch(e) {
        sessionStorage.setItem('textSearch', e.target.value ?? '');
        props.onSearch(e.target.value ?? '');
    }

    function onHelp() {
        props.setHelpPanel(1);
    }

    function togleDrawer() {
        props.setOpen(!open);
    }


    return (
        <Root sx={{ p: 1, zIndex: helpPanel === 3 ? 9999 : 'initial' }}>

            <MyDrawer anchor="top" open={props.open} onClose={() => props.setOpen(false)} >

                {!isLargeScreenPredefined &&
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', p: 1.5 }}>

                        <Box sx={{ border: '1px solid white', display: 'flex', flexWrap: 'nowrap', zIndex: helpPanel === 4 ? 9999 : 'initial' }}>

                            <InputBase
                                sx={{ ml: 1, flex: 1, color: 'white', fontSize: '.9rem', }}
                                placeholder="Search among all profiles..."
                                inputProps={{ 'aria-label': 'Search among all profiles...' }}
                                onChange={onSearch}
                                value={sessionStorage.getItem('textSearch') ?? ''}
                                inputRef={inputRef}
                            />

                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearch}>
                                <Search sx={{ color: 'white' }} />
                            </IconButton>
                        </Box>
                    </Box>
                }

                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                    {isLargeScreenPredefined &&
                        <ColorBox sx={{ p: 1, zIndex: helpPanel === 4 ? 9999 : 'initial' }} variant="contained" onClick={() => inputRef.current.focus()}>

                            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
                                <Box component={'img'} src={'/maintoolbar/search.svg'} sx={{ width: 75, mx: 'auto', mb: 1 }} />

                                <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }} variant="button">Search</Typography>


                                <Box sx={{ borderTop: '1px solid white', pt: 1.5 }}>

                                    <Box sx={{ border: '1px solid white', display: 'flex', flexWrap: 'nowrap' }}>

                                        <InputBase
                                            sx={{ ml: 1, flex: 1, color: 'white', fontSize: '.9rem', }}
                                            placeholder="Search among all profiles..."
                                            inputProps={{ 'aria-label': 'Search among all profiles...' }}
                                            onChange={onSearch}
                                            value={sessionStorage.getItem('textSearch') ?? ''}
                                            inputRef={inputRef}
                                        />

                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearch}>
                                            <Search sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Box>

                                </Box>
                            </Box>
                        </ColorBox>
                    }


                    <ColorBox sx={{ p: 1, zIndex: helpPanel === 5 ? 9999 : 'initial' }} variant="contained" onClick={() => setOpenDialogBestMatch(true)}>

                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >

                            <Box sx={{ width: 'auto', mx: 'auto', mb: 3 }} >

                                <AvatarGroup max={3}>
                                    {defaultData && defaultData.map((item, index) => (
                                        <Avatar onClick={() => console.log(item)} key={index} sx={{ width: '52px', height: '52px' }}
                                            title={item.user.firstname}
                                            alt={item.user.firstname}
                                            src={item.user.avatarUrl ? config.server.backetUrl + item.user.avatarUrl : null} />
                                    ))}
                                </AvatarGroup>

                            </Box>

                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }} variant="button">Your best matches</Typography>


                            <Box sx={{ borderTop: '1px solid white', pt: 1.5 }}>

                                <Typography variant="body2" sx={{ color: 'white', textAlign: 'center', fontSize: '.8rem' }}>
                                    We don't just match you, we tell you how good the match is!
                                </Typography>

                            </Box>
                        </Box>

                    </ColorBox>

                    <ColorBox sx={{ p: 1, zIndex: helpPanel === 6 ? 9999 : 'initial' }} variant="contained" onClick={onOpenFilter}  >

                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
                            <Box component={'img'} src={'/maintoolbar/sliders.svg'} sx={{ width: 75, mx: 'auto', mb: 1 }} ></Box>

                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }} variant="button">Filter</Typography>


                            <Box sx={{ borderTop: '1px solid white', pt: 1.5 }}>

                                <Typography variant="body2" sx={{ color: 'white', textAlign: 'center', fontSize: '.8rem' }}>
                                    Filter profiles based on your preferences
                                </Typography>
                            </Box>

                        </Box>
                    </ColorBox>

                    <ColorBox sx={{ p: 1, zIndex: helpPanel === 7 ? 9999 : 'initial' }} variant="contained" onClick={() => onHelp()} >

                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} >
                            <Box component={'img'} src={'/maintoolbar/help.svg'} sx={{ width: 75, mx: 'auto', mb: 1 }} />

                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }} variant="button">Help</Typography>

                            <Box sx={{ borderTop: '1px solid white', pt: 1.5 }}>
                                <Typography variant="body2" sx={{ color: 'white', textAlign: 'center', fontSize: '.8rem' }}>
                                    Follow the tutorial to help you guide in the matching process
                                </Typography>
                            </Box>
                        </Box>

                    </ColorBox>

                </Box>

            </MyDrawer>

            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', my: 1 }}>
                <Puller onClick={togleDrawer} />
            </Box>
        </Root>
    )
}