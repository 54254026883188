import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';


import Home from './Home';
import { AccIcon } from './tools/Icon.js';
import { Avatar, Box, Button, Divider, Grid, IconButton, InputBase, Link, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Snackbar, SwipeableDrawer, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { Email, Event, EventOutlined, Help, JoinInner, KeyboardBackspace, LinkedIn, PersonSearch, PersonSearchOutlined, Place, Search, Star, StarOutline, Tune } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import config from '../config.js';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import BoxSx from './tools/BoxSx.js';
import DialogFilter from './tools/DialogFilter.js';
import BestMatchDialog from './tools/BestMatchDialog.js';
import HelpPanel from './tools/HelpPanel.js';
import CircularProgressWithLabel from './tools/CircularProgressWithLabel.js';
import ListFavoritesContainer from './tools/ListFavoritesContainer.js';
import ConfirmModal from './tools/ConfirmModal.js';
import UserCard from './UserCard.js';
import ListEventItem from './tools/ListEventItem.js';
import ServerProxy from './tools/serverProxy.js';

const drawerBleeding = 46;

//const drawerSize = 62;


const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[500] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));


const AppBarMobile = styled(Box)(({ theme }) => ({

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0rem 1rem',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '20px',
    borderBlockColor: theme.palette.primary.dark,
    width: '100%'

}));


const Main = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100vh',
    position: 'relative',
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
}));



const MobileAppBarButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '20px',
    borderBlockColor: theme.palette.primary.dark,
    marginRight: '5px',

    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },

    '&:active': {
        backgroundColor: theme.palette.primary.dark,
    },

}));



function ListItemContainer(props) {

    const theme = useTheme();

    const { item, favorites, navigateToProfile, profile } = props;

    const { firstname, lastname, avatarUrl, email, linkedInUrl } = item.user;

    const { profileMacroType } = item

    const [isFavorite, setIsFavorite] = useState(false);
    const [bestMatch, setBestMatch] = useState(false);

    const onClickAction = (item) => {
        navigateToProfile(profile, item)
    }

    const handleEmail = async () => {
        await ServerProxy.saveClick(profile.id, item.id);
    }

    const toggleFavorites = async (item) => {

        if (isFavorite) {
            props.setConfirmMethod({
                item,
                method: props.removeFavorite,
                "callback": () => setIsFavorite(false)
            })


        } else {

            await props.addFavorite(item);
            props.setOpenSnackbar(item.user.firstname + ' ' + item.user.lastname);
            setIsFavorite(true);
        }
    }


    useEffect(() => {
        setBestMatch(parseFloat(item.match) >= config.bestMatchThreshold);
    }, [item.match]);

    useEffect(() => {
        setIsFavorite(favorites.map(favorite => favorite.id).includes(item.id));
    }, [item.id, favorites]);



    return (
        <ListItem sx={{ position: 'relative', width: '100%', p: 1, mb: 1, borderRadius: 1, border: '1px solid #ccc', bgcolor: bestMatch ? theme.palette.primary.dark : grey[200] }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }} >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} >

                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', alignItems: 'center' }} >
                        <ListItemAvatar>
                            <Avatar
                                sx={{ cursor: 'pointer', mr: .5, border: 2, borderColor: bestMatch ? grey[200] : 'primary.main' }}
                                alt={firstname + ' ' + lastname}
                                onClick={() => onClickAction(item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname}
                                src={avatarUrl ? config.server.backetUrl + avatarUrl : null}>{firstname.charAt(0)}</Avatar>
                        </ListItemAvatar>

                        <ListItemText
                            sx={{ flexGrow: 1, m: 0, p: 0 }}

                            primary={<Typography
                                onClick={() => onClickAction(item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname}
                                sx={{ cursor: 'pointer', fontSize: '.8rem', fontWeight: 'bold', color: bestMatch ? grey[200] : 'inherit' }}
                                variant="body">{firstname + ' ' + lastname}</Typography>}

                            secondary={<Typography
                                onClick={() => onClickAction(item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname}
                                sx={{ cursor: 'pointer', fontSize: '.8rem', color: bestMatch ? grey[200] : 'inherit' }}
                                variant="body"
                                display="block" gutterBottom color="text.primary">{profileMacroType.name}</Typography>}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        onClick={() => onClickAction(item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname} >
                        <CircularProgressWithLabel value={item.match} size={3} bestMatch={bestMatch} />
                    </Box>
                </Box>

                <Divider sx={{ width: '100%', my: 1, backgroundColor: bestMatch ? grey[200] : 'inherit' }} />


                {item.positions.map((position, index) => (
                    <Box key={index} sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button sx={{ textTransform: 'none' }} onClick={() => props.flyTo(position)} >
                                <Place sx={{ color: bestMatch ? grey[200] : theme.palette.primary.main, width: 28, mr: 1 }} />
                                <Typography sx={{ color: bestMatch ? grey[200] : 'inherit' }} variant="body2">{position.place}</Typography>
                            </Button>

                        </Box>
                    </Box>
                ))}



                <Divider sx={{ width: '100%', my: 1, backgroundColor: bestMatch ? grey[200] : 'inherit' }} />


                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }} >

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} >
                        {email &&
                            <Box sx={{}} >
                                <IconButton onClick={handleEmail} component={Link} target="_blank" href={`mailto:${email}`} ><Email sx={{ color: bestMatch ? grey[200] : theme.palette.primary.main }} /></IconButton>
                            </Box>
                        }

                        {linkedInUrl &&
                            <Box sx={{}} >
                                <IconButton component={Link} target="_blank" href={linkedInUrl} >
                                    <LinkedIn sx={{ color: bestMatch ? grey[200] : theme.palette.primary.main }} />
                                </IconButton>
                            </Box>
                        }

                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} >

                        <Box sx={{ flex: 1 }} >
                            <IconButton onClick={() => toggleFavorites(item)} >
                                {isFavorite ?
                                    <Star sx={{ color: bestMatch ? grey[200] : theme.palette.primary.main }} />
                                    :
                                    <StarOutline sx={{ color: bestMatch ? grey[200] : theme.palette.primary.main }} />
                                }

                            </IconButton>
                        </Box>

                    </Box>
                </Box>

            </Box>

        </ListItem>
    )
}


function MobileDashboard(props) {

    const theme = useTheme();
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const { window, filters, data, events, favorites, user, profile, navigateToProfile, bestMatches } = props

    const [open, setOpen] = useState(false);
    const [anchorAvaltarEl, setAnchorAvaltarEl] = useState(null);
    const [anchorIconEl, setAnchorIconEl] = useState(null);

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [viewPort, setViewPort] = useState('profiles');

    const [confirmMethod, setConfirmMethod] = useState({
        item: null,
        method: null
    });

    const [flyPosition, setFlyPosition] = useState(null);
    const [drawerSize, setDrawerSize] = useState(90);



    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;


    const onSearch = () => {
        props.onSearch(inputRef.current.value);
    }

    const flyTo = (position) => {
        setFlyPosition(position);
    }


    useEffect(() => {
        //setDrawerSize(90);
        //console.log();
        setOpen(location.pathname !== '/home');

    }, [location]);


    useEffect(() => {
        setViewPort('profiles');
        setDrawerSize(90);
    }, []);


    return (
        <Root >
            <DialogFilter open={props.openDialogFilter} setOpenDialogFilter={props.setOpenDialogFilter} handleClose={props.onCloseFilter} filters={filters} setFilters={props.setFilters} profile={profile} />
            <BestMatchDialog profile={profile} bestMatches={bestMatches} open={props.openDialogBestMatch} navigateToProfile={navigateToProfile} handleClose={() => props.setOpenDialogBestMatch(false)} />
            <HelpPanel open={props.helpPanel} setHelpPanel={props.setHelpPanel} />

            <ConfirmModal confirmMethod={confirmMethod} setConfirmMethod={setConfirmMethod} />

            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
                message="Add to favorites list"
            />

            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(${drawerSize}% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />

            <Main sx={{ width: '100%', height: '100vh', position: 'relative', flex: 1, display: 'flex', justifyContent: 'center' }}>

                <Box maxWidth="xs" sx={{
                    mx: 'auto',
                    position: 'absolute',

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    p: 2,
                    zIndex: props.helpPanel ? 'unset' : 2,

                }}>

                    <AppBarMobile sx={{ zIndex: [3, 4, 9].includes(props.helpPanel) ? 2000 : 2 }}>
                        <IconButton onClick={(event) => setAnchorIconEl(event.currentTarget)}>
                            <AccIcon fill={theme.palette.primary.contrastText} />
                        </IconButton>

                        <Menu
                            anchorEl={anchorIconEl}
                            open={Boolean(anchorIconEl)}
                            onClose={e => setAnchorIconEl(null)}
                            dense
                        >

                            <MenuItem>
                                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }} >
                                    <Link href={config.accelerActionUrl}><Box component='img' src='/AccelerAction_Logo.svg' sx={{ mx: 'auto' }} /></Link>
                                </Box>
                            </MenuItem>

                            <Divider />

                            <MenuItem component={Link} href={config.accelerActionUrl}>
                                <ListItemIcon>
                                    <KeyboardBackspace color="primary" />
                                </ListItemIcon>
                                <ListItemText>Back to homepage</ListItemText>
                            </MenuItem>

                        </Menu>

                        <Box sx={{ display: 'flex', flex: 1, flexWrap: 'nowrap' }}>

                            <InputBase
                                sx={{ ml: 1, p: 0, flex: 1, color: grey[200], fontSize: '.9rem', }}
                                placeholder="Search among all profiles..."
                                inputProps={{ 'aria-label': 'Search among all profiles...' }}
                                onChange={onSearch}
                                inputRef={inputRef}
                            />

                            <IconButton type="button" aria-label="search" onClick={onSearch}>
                                <Search sx={{ color: grey[200] }} />
                            </IconButton>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            <Avatar onClick={(event) => setAnchorAvaltarEl(event.currentTarget)} sx={{ width: 24, height: 24 }} alt={user.firstname + ' ' + user.lastname} src={user.avatarUrl ? config.server.backetUrl + user.avatarUrl : null}>{user.firstname.charAt(0)}</Avatar>


                            <Menu
                                anchorEl={anchorAvaltarEl}
                                open={Boolean(anchorAvaltarEl)}
                                onClose={e => setAnchorAvaltarEl(null)}
                                dense
                            >

                                <MenuItem
                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
                                    onClick={props.onExit}
                                >
                                    <ListItemIcon>
                                        <Tooltip title={user.firstname + ' ' + user.lastname} >
                                            <Avatar sx={{ width: 28, height: 28, mr: { xs: 0, sm: 1 } }} alt={user.firstname + ' ' + user.lastname} src={user.avatarUrl ? config.server.backetUrl + user.avatarUrl : null}>{user.firstname.charAt(0)}</Avatar>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText>Go to your profile</ListItemText>

                                </MenuItem>

                                <Divider />

                                <MenuItem
                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
                                    onClick={() => { setAnchorAvaltarEl(null); props.setOpenDialogBestMatch(true) }}
                                >
                                    <ListItemIcon>
                                        <JoinInner color="primary" />
                                    </ListItemIcon>
                                    <ListItemText>Your best matches</ListItemText>
                                </MenuItem>

                                <MenuItem
                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
                                    onClick={() => { setAnchorAvaltarEl(null); props.onOpenFilter() }}
                                >
                                    <ListItemIcon>
                                        <Tune color="primary" />
                                    </ListItemIcon>
                                    <ListItemText>Manual filters</ListItemText>
                                </MenuItem>


                                <MenuItem
                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
                                    onClick={() => { setAnchorAvaltarEl(null); props.setHelpPanel(1) }}
                                >
                                    <ListItemIcon>
                                        <Help color="primary" />
                                    </ListItemIcon>
                                    <ListItemText>Help</ListItemText>
                                </MenuItem>


                                <Divider />

                                {user.profiles.map((item, index) => (
                                    <MenuItem key={index}

                                        onClick={() => {
                                            profile.setProfile(item);
                                            setAnchorAvaltarEl(null)
                                            navigate('/home');
                                        }}

                                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                                    >

                                        <Typography sx={{ mr: 1, color: item.id === profile.id ? 'primary.main' : 'inherit' }} >{item.name}</Typography>
                                        <BoxSx>{item.profileMacroType.name}</BoxSx>

                                    </MenuItem>
                                ))}

                            </Menu>

                        </Box>

                    </AppBarMobile>


                    <Box sx={{ width: '100%', overflow: 'auto', whiteSpace: 'nowrap', mt: 1 }}>
                        <MobileAppBarButton sx={{ whiteSpace: 'nowrap', zIndex: [3, 5].includes(props.helpPanel) ? 2000 : 2 }} onClick={() => { setAnchorAvaltarEl(null); props.setOpenDialogBestMatch(true) }}><JoinInner sx={{ mr: 1 }} /> Best match</MobileAppBarButton>
                        <MobileAppBarButton sx={{ whiteSpace: 'nowrap', zIndex: [3, 6].includes(props.helpPanel) ? 2000 : 2 }} onClick={() => { setAnchorAvaltarEl(null); props.onOpenFilter() }}><Tune sx={{ mr: 1 }} /> Filter</MobileAppBarButton>
                        <MobileAppBarButton sx={{ whiteSpace: 'nowrap', zIndex: [3, 7].includes(props.helpPanel) ? 2000 : 2 }} onClick={() => { setAnchorAvaltarEl(null); props.setHelpPanel(1) }}><Help sx={{ mr: 1 }} />Help</MobileAppBarButton>
                    </Box>

                </Box>

                <Home
                    filters={filters}
                    openDialogFilter={props.openDialogFilter}
                    data={data}
                    onClickAction={props.onClickAction}
                    mobile={true}
                    flyPosition={flyPosition}
                />
            </Main>


            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    zIndex: [2, 8].includes(props.helpPanel) ? 2000 : 2,
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                    }}
                >
                    <Puller />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ p: 1.6, color: 'primary' }}>
                            {viewPort === 'profiles' ? 'Profiles' : viewPort === 'events' ? 'Events' : 'Favorites'} ({viewPort === 'profiles' ? data.length : viewPort === 'events' ? events.length : favorites.length})
                        </Typography>

                        <Box>
                            <IconButton onClick={() => setViewPort('profiles')} >
                                {viewPort === 'profiles' ? <PersonSearch color='primary' /> : <PersonSearchOutlined color='primary' />}
                            </IconButton>

                            <IconButton onClick={() => setViewPort('events')} >
                                {viewPort === 'events' ? <Event color='primary' /> : <EventOutlined color='primary' />}
                            </IconButton>

                            <IconButton onClick={() => setViewPort('favorites')} >
                                {viewPort === 'favorites' ? <Star color='primary' /> : <StarOutline color='primary' />}
                            </IconButton>
                        </Box>
                    </Box>

                </StyledBox>

                <StyledBox
                    sx={{
                        px: 1,
                        pb: 2,
                        height: '100%',
                        overflow: 'auto',
                    }}
                >

                    <Routes>
                        <Route path="/home" element={
                            <Grid container spacing={0} columnSpacing={1} >

                                {viewPort === 'profiles' && (

                                    data.length === 0 ? (
                                        <Grid item xs={12} >
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', p: 5 }}>
                                                <Typography variant="body1" color="text.secondary">No profiles yet</Typography>
                                            </Box>
                                        </Grid>

                                    ) : (
                                        data.map((item, index) => (

                                            <Grid item xs={12} sm={6} key={index} >
                                                <ListItemContainer item={item} favorites={favorites}
                                                    flyTo={flyTo}
                                                    setOpenSnackbar={setOpenSnackbar}
                                                    profile={profile}
                                                    navigateToProfile={navigateToProfile}
                                                    setConfirmMethod={setConfirmMethod}
                                                    removeFavorite={props.removeFavorite}
                                                    addFavorite={props.addFavorite} />
                                            </Grid>
                                        ))
                                    )
                                )}

                                {viewPort === 'events' && (

                                    events.length === 0 ? (
                                        <Grid item xs={12} >
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', p: 5 }}>
                                                <Typography variant="body1" color="text.secondary">No events yet</Typography>
                                            </Box>
                                        </Grid>

                                    ) : (
                                        events.map((item, index) => (

                                            <Grid item xs={12} sm={6} key={index} >
                                                {/* <ListItemContainer key={index} item={item} favorites={favorites}
                                                flyTo={flyTo}
                                                setOpenSnackbar={setOpenSnackbar}
                                                setConfirmMethod={setConfirmMethod}
                                                removeFavorite={props.removeFavorite}
                                                addFavorite={props.addFavorite} /> */}
                                                <ListEventItem item={item} />

                                            </Grid>
                                        ))
                                    )
                                )}


                                {viewPort === 'favorites' &&
                                    (
                                        favorites.length === 0 ? (
                                            <Grid item xs={12} >
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', p: 5 }}>
                                                    <Typography variant="body1" color="text.secondary">No favorites yet</Typography>
                                                </Box>
                                            </Grid>
                                        ) : (

                                            favorites.map((item, index) => (
                                                <Grid item xs={12} sm={6} key={index} >
                                                    <ListFavoritesContainer item={item}
                                                        deleteItem={() => setConfirmMethod({ item, method: props.removeFavorite })}
                                                    />
                                                </Grid>
                                            ))

                                        )
                                    )
                                }

                            </Grid>
                        } />

                        <Route path='/user/:id/profile/:idProfile' element={<UserCard flyTo={flyTo} />} />
                        <Route path="/*" element={<Navigate to="/home" replace />} />

                    </Routes>

                </StyledBox>

            </SwipeableDrawer>
        </Root>
    );
}

MobileDashboard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default MobileDashboard;