import axios from "axios";
import config from "../../config";
import md5 from "md5";

const minuteThreshold = 1 * 60 * 1000;

const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;

const token = sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')) : '';

const ServerProxy = {


    loginToken: async (target) => {

        try {
            const response = await axios.post(
                url + '/oauth/login_token',
                {
                    token: target,
                    data: md5(target)
                }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response && response.data) {

                const { user, token } = response.data;

                if (user && user.id) {

                    console.log('loginToken', user, token);

                    sessionStorage.clear();
                    sessionStorage.setItem('user', JSON.stringify({ id: user.id }));
                    sessionStorage.setItem('role', 'dashboard');
                    sessionStorage.setItem('token', JSON.stringify(token));

                    return token;
                }

                else {
                    sessionStorage.clear();
                }

                throw new Error('Invalid token');
            }
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    },


    refreshTokens: async () => {

        try {

            const response = await axios.post(
                url + '/oauth/access_token/refresh',
                {
                    grant_type: "refresh_token",
                    refresh_token: token.token
                }, {

                headers: {
                    'Content-Type': 'application/json',
                }
            }
            );

            if (response.data) {
                const token = response.data;
                sessionStorage.setItem('token', JSON.stringify(token));

                return token;
            }

        } catch (error) {
            console.log(error);
            throw error;
        }
    },


    getRegions: async () => {

        if (sessionStorage.getItem('regions')) {
            return JSON.parse(sessionStorage.getItem('regions'));
        }


        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();


            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + '/regions', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('regions', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },


    getProfileTypes: async () => {

        if (sessionStorage.getItem('profileTypes')) {
            return JSON.parse(sessionStorage.getItem('profileTypes'));
        }

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();


            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + '/profileTypes', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('profileTypes', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },


    getEspertiseAreas: async () => {

        if (sessionStorage.getItem('expertise')) {
            return JSON.parse(sessionStorage.getItem('expertise'));
        }


        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + '/expertise', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('expertise', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },

    getCompanyFocus: async () => {

        if (sessionStorage.getItem('companyFocus')) {
            return JSON.parse(sessionStorage.getItem('companyFocus'));
        }


        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + '/companyFocus', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('companyFocus', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },


    getInvestorType: async () => {

        if (sessionStorage.getItem('investorType')) {
            return JSON.parse(sessionStorage.getItem('investorType'));
        }


        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + '/investorType', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('investorType', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },

    getTopic: async () => {

        if (sessionStorage.getItem('topic')) {
            return JSON.parse(sessionStorage.getItem('topic'));
        }


        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + '/topic', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('topic', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },

    /*serviceDeliveryType*/
    getServiceDeliveryType: async () => {

        if (sessionStorage.getItem('serviceDeliveryType')) {
            return JSON.parse(sessionStorage.getItem('serviceDeliveryType'));
        }


        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + '/serviceDeliveryType', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('serviceDeliveryType', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },

    /* trainingProviderCategory */
    getTrainingProviderCategory: async () => {

        if (sessionStorage.getItem('trainingProviderCategory')) {
            return JSON.parse(sessionStorage.getItem('trainingProviderCategory'));
        }

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + '/trainingProviderCategory', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('trainingProviderCategory', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },

    /* typology */
    getTypology: async (entity) => {

        if (sessionStorage.getItem('typology' + entity)) {
            return JSON.parse(sessionStorage.getItem('typology' + entity));
        }

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const response = await axios.get(
                    url + '/typology?entity=' + entity, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('typology' + entity, JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },


    getActivityLevel: async () => {

        if (sessionStorage.getItem('activityLevel')) {
            return JSON.parse(sessionStorage.getItem('activityLevel'));
        }

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const response = await axios.get(
                    url + '/activityLevel', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('activityLevel', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },

    getService: async () => {

        if (sessionStorage.getItem('service')) {
            return JSON.parse(sessionStorage.getItem('service'));
        }

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const response = await axios.get(
                    url + '/service', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('service', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },


    getIncubatorsType: async () => {

        if (sessionStorage.getItem('incubatorsType')) {
            return JSON.parse(sessionStorage.getItem('incubatorsType'));
        }

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const response = await axios.get(
                    url + '/incubatorsType', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    sessionStorage.setItem('incubatorsType', JSON.stringify(response.data.data));
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },

    /* additionalInformation */
    getAdditionalInformation: async (idProfile) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const response = await axios.get(
                    url + '/profiles/' + idProfile + '/additionalInformation', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },


    getUser: async (id) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + '/users/' + id, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },


    getProfile: async (idUser, idProfile) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const response = await axios.get(
                    url + '/users/' + idUser + '/profiles/' + idProfile, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });

                if (response.data) {
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }

    },


    getDefaultProfile: async (idUser) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const response = await axios.get(
                    url + '/users/' + idUser + '/profiles?defaultProfile=1', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.token
                    }
                });


                if (response.data) {

                    if (response.data.data && response.data.data.length > 0) {
                        return response.data.data[0];
                    }
                }

            } catch (error) {
                console.log(error);
                throw error;
            }

            return null;
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }

    },


    getMatches: async (entity, profileId, filter, include = []) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + `/profiles/${entity}/${profileId}`,
                    {
                        params: { ...filter, include },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.token
                        }
                    }
                );


                if (response.data) {
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },

    getProfiles: async (filter) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + '/profiles/',
                    {
                        params: { ...filter },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token.token}`
                        }
                    }
                );

                if (response.data) {
                    return response.data.data;
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }

    },


    saveFilters: async (currentFilters) => {


        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const { profile } = currentFilters;

                console.log('saveFilters', profile, currentFilters);

                //{{host}}/api/:version/users/:id/profiles/:idProfile/lookingFor
                const user = JSON.parse(sessionStorage.getItem('user'));

                const response = await axios.patch(
                    url + `/users/${user.id}/profiles/${profile.id}/lookingFor`,
                    { "profileTypeIds": currentFilters.type },

                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.token
                        }
                    }
                );

                if (response.data) {
                    return response.data.data;
                }


            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }

    },




    getFavorites: async (profileId) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const userId = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).id : null;

                if (userId) {
                    const response = await axios.get(
                        url + `/users/${userId}/profiles/${profileId}/favorites`,
                        {
                            params: {},
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token.token}`
                            }
                        }
                    );

                    //console.log('getFavorites', response.data.favoritesProfiles); 

                    if (response.data) {
                        return response.data.favoritesProfiles;
                    }

                    return [];
                }

                else {
                    throw new Error('Invalid user');
                }

            } catch (error) {
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }

    },

    removeFavorite: async (profileId, favoriteId) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const userId = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).id : null;

                if (userId) {
                    const response = await axios.delete(
                        url + `/users/${userId}/profiles/${profileId}/favorites`,

                        {
                            data: { "profileId": favoriteId },
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token.token}`
                            }
                        }
                    );

                    //console.log('getFavorites', response.data);

                    if (response.data) {
                        return response.data.favoritesProfiles;
                    }

                    return [];

                    //throw new Error('Invalid data');

                }

                else {
                    throw new Error('Invalid user');
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }


    },


    addFavorite: async (profileId, favoriteId) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));


                const userId = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).id : null;

                if (userId) {
                    const response = await axios.post(
                        url + `/users/${userId}/profiles/${profileId}/favorites`,
                        {
                            "profileId": favoriteId
                        }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token.token}`
                        }
                    }
                    );


                    if (response.data) {
                        return response.data.favoritesProfiles;
                    }

                    return [];

                    //throw new Error('Invalid data');

                }

                else {
                    throw new Error('Invalid user');
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }

    },




    getLiked: async (profileId) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const userId = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).id : null;

                if (userId) {
                    const response = await axios.get(
                        url + `/users/${userId}/profiles/${profileId}/liked`,

                        {
                            params: {},
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token.token}`
                            }
                        }
                    );


                    if (response.data) {
                        return response.data.likedProfiles;
                    }

                    return [];

                    //throw new Error('Invalid data');

                }

                else {
                    throw new Error('Invalid user');
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }

    },

    removeLiked: async (profileId, likedId) => {
        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const userId = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).id : null;

                if (userId) {
                    const response = await axios.delete(
                        url + `/users/${userId}/profiles/${profileId}/liked`,

                        {
                            data: { "profileId": likedId },
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token.token}`
                            }
                        }
                    );

                    //console.log('getFavorites', response.data);

                    if (response.data) {
                        return response.data.favoritesProfiles;
                    }

                    return [];

                    //throw new Error('Invalid data');

                }

                else {
                    throw new Error('Invalid user');
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },


    addLiked: async (profileId, likedId) => {

    },


    getEvents: async (userId) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                if (userId) {
                    const response = await axios.get(
                        url + `/users/${userId}/events`,
                        {
                            params: { status: 1 },
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token.token}`
                            }
                        }
                    );


                    if (response.data) {
                        return response.data.data
                    }

                    return [];

                }

                else {
                    throw new Error('Invalid user');
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },


    deleteEvent: async (eventId) => {
        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));


                const response = await axios.delete(

                    url + `/events/${eventId}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token.token}`
                        }
                    }
                );

                if (response) {
                    return response
                }

            } catch (error) {
                console.log(error);
                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },


    getEvent: async (eventId) => {

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.get(
                    url + `/events/${eventId}`,
                    {
                        params: {},
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token.token}`
                        }
                    }
                );


                if (response.data) {
                    return { status: response.status, data: response.data.data };
                }

                return null;


            } catch (error) {

                console.log(error);

                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }

    },


    updateEventStatus: async (eventId, status) => {
        console.log('updateEventStatus', status, eventId);

        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.patch(
                    url + `/events/${eventId}`,
                    { status },

                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.token
                        }
                    }
                );


                if (response.data) {
                    return response;
                }

                return null;

            } catch (error) {
                console.log(error);

                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },

    updateEvent: async (event) => {


        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.patch(
                    url + `/events/${event.id}`,
                    { ...event },

                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.token
                        }
                    }
                );


                if (response.data) {
                    return response;
                }

                return null;

            } catch (error) {
                console.log(error);

                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    },

    saveClick: async (profileId, clickedId) => {
        if (token) {

            const { exp } = token;

            const dateToCheck = new Date(exp * 1000) - new Date();

            if (dateToCheck < 0) {
                sessionStorage.clear();
                return window.location.assign(config.VES_URL);
            }

            else if (dateToCheck < minuteThreshold) {
                await ServerProxy.refreshTokens();
            }

            try {

                const token = JSON.parse(sessionStorage.getItem('token'));

                const response = await axios.post(
                    url + `/profilesinteractions/${profileId}/submit`,
                    { clickedId },

                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.token
                        }
                    }
                );


                if (response.data) {
                    return response;
                }

                return null;

            } catch (error) {
                console.log(error);

                throw error;
            }
        }

        else {
            sessionStorage.clear();
            return window.location.assign(config.VES_URL);
        }
    }
}


export default ServerProxy;